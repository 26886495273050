import { Location } from 'history'
import { ActionTypes, types } from './global.types'
import ChannelInterface, { ChannelSiteInterface } from '../../interfaces/ChannelInterface'
import { SizeGuideInterface } from '../../interfaces/SizeGuideInterface'

export interface ConfigInterface {
  loyaltyPointsConvertRate: number
  loyaltyPointsGainRate: number
  channels: {
    current: ChannelInterface
    alternate: ChannelInterface
  }
  channelSites: ChannelSiteInterface[]
  countryByIp: string | null
  recommendedChannel: ChannelSiteInterface | null
  sizeGuide: SizeGuideInterface[]
}

interface ChannelUpdatePayload {
  current: ChannelInterface
  alternate: ChannelInterface
}

interface LocationsUpdatePayload {
  channelId: number
  lastLocation?: Location
  prevLocation?: Location
}

export function updateChannelSuccess(data: ChannelUpdatePayload): ActionTypes {
  return {
    type: types.CHANNEL_UPDATE_SUCCESS,
    payload: data,
  }
}

export function updateLocations(data: LocationsUpdatePayload): ActionTypes {
  return {
    type: types.LOCATIONS_UPDATE_SUCCESS,
    payload: data,
  }
}

export function readConfigSuccess(data: ConfigInterface): ActionTypes {
  return {
    type: types.READ_CONFIG_SUCCESS,
    payload: data,
  }
}
