import { FormControlProps } from '@mui/material/FormControl'
import _ from 'lodash'
import * as React from 'react'
import CheckboxField from '../CheckboxField'

interface Props {
  field: {
    name: string
    value: any
    onChange: (event: any, values: any) => void
    onBlur: (event: any) => void
  }
  form: {
    touched?: Object
    errors?: Object
  }
  label: React.ReactElement | string
  formControlProps?: FormControlProps
  classes?: {
    root?: string
    labelRoot?: string
    labelText?: string
  }
}

const FormCheckboxField = (props: Props) => {
  const { form, field, formControlProps, classes } = props

  return (
    <CheckboxField
      {...field}
      label={props.label}
      error={Boolean(_.has(form.touched, field.name) && _.has(form.errors, field.name))}
      helperText={_.has(form.touched, field.name) && _.has(form.errors, field.name) ? _.get(form.errors, field.name) : ''}
      formControlProps={formControlProps}
      classes={classes}
    />
  )
}

export default FormCheckboxField
