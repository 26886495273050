import { Location } from 'history'
import ChannelInterface from '../../interfaces/ChannelInterface'
import { SizeGuideInterface } from '../../interfaces/SizeGuideInterface'

export enum types {
  CHANNEL_UPDATE_SUCCESS = 'CHANNEL_UPDATE_SUCCESS',
  LOCATIONS_UPDATE_SUCCESS = 'LOCATIONS_UPDATE_SUCCESS',
  READ_CONFIG_SUCCESS = 'GLOBAL_READ_CONFIG_SUCCESS',
}

export interface UpdateChannelSuccess {
  type: types.CHANNEL_UPDATE_SUCCESS
  payload: {
    current: ChannelInterface
    alternate: ChannelInterface
  }
}

export interface UpdateLocationsSuccess {
  type: types.LOCATIONS_UPDATE_SUCCESS
  payload: {
    channelId: number
    lastLocation?: Location
    prevLocation?: Location
  }
}

export interface ReadAppStateSuccess {
  type: types.READ_CONFIG_SUCCESS
  payload: any
}

export type ActionTypes = UpdateChannelSuccess | UpdateLocationsSuccess | ReadAppStateSuccess
