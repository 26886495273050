import React, { FC } from 'react'
import AppContext, { ContextProps } from '../../context/AppContext'

const AppProvider: FC<ContextProps> = ({ children, redirect, subscribers }) => {
  return (
    <AppContext.Provider value={{ redirect, subscribers }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
