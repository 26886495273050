import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { IconButton } from '@mui/material'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import styles from './NumberField.module.scss'

type Props = {
  value: number
  handleClick: (value: number, onError: (lastVal: number) => void) => void
  minValue: number
  maxValue: number
  disabled?: boolean
  readonly?: boolean
  classes?: {
    root?: string
    input?: string
  }
}
const NumberField = (props: Props) => {
  const [value, setValue] = useState<string>(props.value.toString())
  const changeTimer = React.useRef<NodeJS.Timeout | null>(null)
  const valInt = parseInt(value, 10)
  useEffect(() => {
    if (props.value.toString() !== value) {
      setValue(props.value.toString())
    }
  }, [props.value])

  const onValueChange = (newVal: number) => {
    props.handleClick(newVal, (oldVal: number) => {
      setValue(oldVal.toString())
    })
  }

  const handleIncrementClick = () => {
    onValueChange(valInt + 1)
  }

  const handleDecrementClick = () => {
    onValueChange(valInt - 1)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, forceValidation?: boolean) => {
    const targetValue = parseInt(event.target?.value, 10)
    let qty = targetValue > props.maxValue ? props.maxValue : targetValue
    qty = qty < props.minValue ? props.minValue : qty

    clearTimeout(changeTimer.current!)
    changeTimer.current = setTimeout(
      () => {
        onValueChange(qty)
      },
      forceValidation ? 100 : 1000,
    )

    if (!qty) {
      if (/^[\d-]?$/.test(event.target?.value) || forceValidation) {
        setValue(props.minValue >= 0 && event.target?.value === '-' ? '' : event.target?.value)
      }
      if (!forceValidation) clearTimeout(changeTimer.current)
      return
    }
    setValue(qty.toString())
  }

  return (
    <div className={classNames(styles['number-field'], props.classes?.root)}>
      <IconButton
        classes={{ root: styles['number-field__button'] }}
        disabled={valInt <= props.minValue || props.disabled}
        onClick={() => handleDecrementClick()}
        size="small"
      >
        <RemoveIcon fontSize="inherit" />
      </IconButton>
      <TextField
        onBlur={event => handleChange(event, true)}
        disabled={props.disabled || props.readonly}
        value={value}
        classes={{ root: classNames(styles['number-field__value'], props.classes?.input) }}
        margin="none"
        inputProps={{
          sx: { textAlign: 'center' },
        }}
        onChange={handleChange}
      />
      <IconButton
        classes={{ root: styles['number-field__button'] }}
        disabled={valInt >= props.maxValue || props.disabled}
        onClick={() => handleIncrementClick()}
        size="small"
      >
        <AddIcon fontSize="inherit" />
      </IconButton>
    </div>
  )
}

export default NumberField
