import ls from '@livesession/sdk'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import messages from './cart.messages'
import notify from '../../components/Notification/notify'
import * as actions from '../../services/cart/cart.actions'
import * as api from '../../services/cart/cart.api'
import { State } from '../../services/reducers'
import { AxiosError, AxiosErrorCallback, AxiosResponse, AxiosSuccessCallback } from '../../utils/axios'
import { handleErrorResponse } from '../../utils/formErrorHelper'

interface ProductPayload {
  productId: number
  quantity: number
}

export const useCart = () => {
  const dispatch = useDispatch()
  const cart = useSelector((state: State) => state.cart)
  const intl = useIntl()

  const getProductCartQty = (productId: number) => {
    const foundProduct = cart?.items.find(cartItem => {
      return cartItem.product.id === productId
    })
    return foundProduct?.quantity || 0
  }

  const addProduct = (
    payload: ProductPayload,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
    withSuccessMessages: boolean = true,
    withErrorMessages: boolean = true,
  ) => {
    if (payload.quantity < 1) {
      notify.error(intl.formatMessage(messages.wrongQuantity))
      return
    }

    api
      .createCartProduct(payload.productId, payload.quantity)
      .then((response: AxiosResponse) => {
        withSuccessMessages ? notify.success(intl.formatMessage(messages.productAdded)) : _.noop()
        ls.track('Add to cart', {
          productId_int: payload.productId,
          quantity_int: payload.quantity,
        })
        _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
        dispatch(actions.cartReadRequest())
      })
      .catch((error: AxiosError) => {
        withErrorMessages ? handleErrorResponse(error.response) : _.noop()
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const removeProduct = (
    productId: number,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
    withMessages: boolean = true,
  ) => {
    api
      .updateCartProduct(productId, 0)
      .then((response: AxiosResponse) => {
        withMessages ? notify.success(intl.formatMessage(messages.productRemoved)) : _.noop()
        ls.track('Remove from cart', {
          productId_int: productId,
        })
        _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
        dispatch(actions.cartReadSuccess(response.data))
      })
      .catch((error: AxiosError) => {
        withMessages ? handleErrorResponse(error.response) : _.noop()

        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const adjustProduct = (
    payload: ProductPayload,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
    withMessages: boolean = true,
  ) => {
    if (payload.quantity < 1) {
      notify.error(intl.formatMessage(messages.wrongQuantity))
      return
    }

    api
      .updateCartProduct(payload.productId, payload.quantity)
      .then((response: AxiosResponse) => {
        withMessages ? notify.success(intl.formatMessage(messages.productAdjusted)) : _.noop()
        ls.track('Change quantity in cart', {
          productId_int: payload.productId,
          quantity_int: payload.quantity,
        })
        _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
        dispatch(actions.cartReadSuccess(response.data))
      })
      .catch((error: any) => {
        console.log(error)
        if (error?.response?.data?.fields?.quantity) {
          const product = cart.items.find(item => item.product.id === payload.productId)
          const errorMessage = error?.response?.data?.fields?.quantity![0] || ''
          const quantity = errorMessage.match(/^\d+|\d+\b|\d+(?=\w)/g)[0]
          withMessages
            ? notify.error(intl.formatMessage(messages.productAdjustedError, { quantity, productName: product?.name }))
            : _.noop()
        }
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const clearCart = () => {
    dispatch(actions.cartDeleteRequest())
  }

  const addVoucher = (
    code: string,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    api
      .createCartVoucher(code)
      .then((response: AxiosResponse) => {
        _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
        dispatch(actions.cartReadSuccess(response.data))
      })
      .catch((error: AxiosError) => {
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const removeVoucher = (
    id: number,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    api
      .deleteCartVoucher(id)
      .then((response: AxiosResponse) => {
        _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
        dispatch(actions.cartReadSuccess(response.data))
      })
      .catch((error: AxiosError) => {
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const applyLoyaltyPoints = (
    points: number | string,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    api
      .createCartLoyaltyPoints(parseInt(points.toString(), 10))
      .then((response: AxiosResponse) => {
        _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
        dispatch(actions.cartReadSuccess(response.data))
      })
      .catch((error: AxiosError) => {
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const removeGiftVoucher = (
    id: number,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    api
      .deleteGiftVoucher(id)
      .then((response: AxiosResponse) => {
        _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
        dispatch(actions.cartReadSuccess(response.data))
      })
      .catch((error: AxiosError) => {
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const refreshCart = (
    strict: boolean,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    api
      .updateCart(cart, true)
      .then((response: AxiosResponse) => {
        _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
        dispatch(actions.cartReadSuccess(response.data))
      })
      .catch((error: AxiosError) => {
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  return {
    addProduct,
    removeProduct,
    adjustProduct,
    clearCart,
    addVoucher,
    removeVoucher,
    applyLoyaltyPoints,
    removeGiftVoucher,
    cart,
    getProductCartQty,
    refreshCart,
  }
}

export default useCart
