const routes = {
  home: '/',
  search: '/search',
  cms: '/pages/:pageId(\\d+)-:pageSlug([\\w][\\w-]*)',
  cmsPage: '/page/:pageId(\\d+)-:pageSlug([\\w][\\w-]*)',
  sizeGuide: '/size-guide',
  contact: '/contact',
  storeLocator: '/store-locator',

  blogPost: '/blog/:tagSlug([\\w][\\w-]*)/:postId(\\d+)-:postSlug([\\w][\\w-]*)',
  blogTag: '/blog/:tagId(\\d+)-:tagSlug([\\w][\\w-]*)',
  blog: '/blog',
  catalog: '/collection/:categoryId(\\d+)-:categorySlug([\\w][\\w-]*)',
  product: '/product/:productId(\\d+)-:productSlug([\\w][\\w-]*)',
  giftVoucher: '/gift',

  cart: '/cart',
  cartAuth: '/cart/authenticate',
  cartDelivery: '/cart/delivery',
  cartPayment: '/cart/payment',
  cartEwayReturn: '/cart/eway/:methodId(\\d+)',
  cartConfirmation: '/cart/confirmation/:token(.*)',
  cartRestore: '/cart/reminder',

  authLogin: '/account/authenticate',
  authForgotPasswordToken: '/account/forgot-password/:token([\\w-.]*)',
  authForgotPassword: '/account/forgot-password',

  user: '/account',
  userInfo: '/account/info',
  userEdit: '/account/edit',
  userChangePassword: '/account/change-password',
  userOrders: '/account/orders',
  userRefunds: '/account/refunds',
  userOrder: '/account/orders/:orderId(\\d+)',
  userAddresses: '/account/addresses',
  userAddress: '/account/addresses/:addressId(\\d+)',
  userVouchers: '/account/vouchers',
  userPoints: '/account/feedthefunkys',
  userTracking: '/account/tracking',
  userLoyaltyPoints: '/account/loyalty-points',
  userReferralProgram: '/account/referral-program',
  userWishlist: '/account/wishlist',
  userOrderReturn: '/account/return/create',
  guestOrder: '/support/order/:token([\\w-.]*)',
  guestOrderReturn: '/support/return/create/:token([\\w-.]*)',
  returnRequest: '/return-request',
  userReturnConfirmation: '/account/return/confirmation/:returnOrderId([\\d+]*)/:orderId([\\d+]*)',
  guestReturnConfirmation: '/support/return/confirmation/:returnOrderId(\\d+)/:orderId([\\d+]*)/:token([\\w-.]*)',

  clubOffers: '/club/offers',
}

export default routes
