export enum CHANNEL_PARAMS {
  FUNKITA_CONTINUE_SHOPPING_URL = 'funkitaContinueShoppingUrl',
  FUNKITA_SWIMWEAR_URL = 'funkitaSwimwearUrl',
  FUNKITA_GEAR_URL = 'funkitaGearUrl',
  FUNKITA_OUTLET_URL = 'funkitaOutletUrl',
  FUNKITA_HEADER_TEXT_LEFT = 'headerTextLeft',
  FUNKITA_HEADER_TEXT_RIGHT = 'headerTextRight',
  FUNKYTRUNKS_CONTINUE_SHOPPING_URL = 'funkytrunksContinueShoppingUrl',
  FUNKYTRUNKS_SWIMWEAR_URL = 'funkytrunksSwimwearUrl',
  FUNKYTRUNKS_GEAR_URL = 'funkytrunksGearUrl',
  FUNKYTRUNKS_OUTLET_URL = 'funkytrunksOutletUrl',
  FUNKYTRUNKS_HEADER_TEXT_LEFT = 'headerTextLeft',
  FUNKYTRUNKS_HEADER_TEXT_RIGHT = 'headerTextRight',
}

export type ChannelConfig = {
  continueShoppingUrl: string
  swimwearUrl: string
  gearUrl: string
  outletUrl: string
  headerTextLeft: string
  headerTextRight: string
}
