//disable-sort-imports
//@ts-nocheck TODO: fix it
import cardValidator from 'card-validator'
import _ from 'lodash'
import moment from 'moment'
import postalCodes from 'postal-codes-js'
import * as Yup from 'yup'
import countriesList from './countries'
import DateHelper from './date'
import { getStatesNames } from './states'
import store from '../services/store'
import { DEFAULT_LANGUAGE_ISO_CODE } from './locale'
import { COUNTRY_CODE_KR } from '../hooks/address'
import parsePhoneNumber, { isValidPhoneNumber, isSupportedCountry } from 'libphonenumber-js'

type FieldKeyType = 'firstName|lastName|street|street2|city|postCode|country|phoneNumber'

export type FieldsRequiredType = {
  [key: string]: boolean
}

function isValidDate(message: string) {
  return this.test('date-is-valid', message, function (value) {
    const { path, createError } = this
    moment.locale(store.getState().locale.language.isoCode)
    const momentDate = moment(value, DateHelper.getDateFormatPattern(moment as unknown as moment.Moment), true)

    if (!value && this.schema.spec.nullable) {
      return true
    }
    if (momentDate.isValid()) {
      return true
    }

    return createError({
      path,
      message,
    })
  })
}

function isValidDateRange(
  minMomentDate: moment.Moment,
  maxMomentDate: moment.Moment,
  wrongDateMessage: string,
  wrongMinDateMessage: string,
  wrongMaxDateMessage: string,
) {
  return this.test('date-range-is-valid', wrongDateMessage, function (value) {
    const { path, createError } = this
    //TODO: possible bug when DateField will has passed forced dateFormat
    const momentDate = moment(
      value,
      DateHelper.getDateFormatPattern(moment as unknown as moment.Moment, 'L'),
      (store.getState().locale.language.isoCode || DEFAULT_LANGUAGE_ISO_CODE).toLowerCase(),
      true,
    )

    if (!value && this.schema.spec.nullable) {
      return true
    }

    if (!momentDate.isValid()) {
      const message = wrongDateMessage
      return createError({
        path,
        message,
      })
    }
    if (minMomentDate > momentDate) {
      const message = wrongMinDateMessage
      return createError({
        path,
        message,
      })
    }

    if (maxMomentDate < momentDate) {
      const message = wrongMaxDateMessage
      return createError({
        path,
        message,
      })
    }

    return true
  })
}

function equalTo(ref: any, msg: any) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || `${path} must be the same as ${reference}`,
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref)
    },
  })
}

function isValidCountryIsoCode(message: string) {
  return this.test('country-iso-code', message, function (value) {
    const { path, createError } = this
    const isoCode = countriesList.getAlpha2Code(value, _.get(store.getState(), 'locale.language.shortIsoCode', 'en'))
    if (countriesList.isValid(isoCode)) {
      return true
    }

    return createError({
      path,
      message,
    })
  })
}

function isValidCardNumber(message: string) {
  return this.test('is-card-number-valid', message, function (value) {
    const { path, createError } = this
    const valid = cardValidator.number(value)
    return (
      valid.isPotentiallyValid ||
      createError({
        path,
        message,
      })
    )
  })
}

function isValidCvv(message: string) {
  return this.test('is-cvv-valid', message, function (value) {
    const { path, createError } = this
    const valid = cardValidator.cvv(value, 4)
    if (value.length > 2 && value.length < 5 && valid.isPotentiallyValid) return true

    return createError({
      path,
      message,
    })
  })
}

function isValidCardExpirationDate(message: string) {
  return this.test('is-expiration-date-of-card-valid', message, function (value) {
    const { path, createError } = this
    const valid = cardValidator.expirationDate(value)
    return (
      valid.isValid ||
      createError({
        path,
        message,
      })
    )
  })
}

function isValidPostCode(ref: any, message: string) {
  return this.test('date-range-is-valid', message, function (value: string) {
    const { path, createError } = this

    const country = this.resolve(ref)
    if (!country) return true

    const countryCode = countriesList.getAlpha2Code(country, _.get(store.getState(), 'locale.language.shortIsoCode', 'en'))
    const valid = postalCodes.validate(countryCode, value)
    if (!value && this.schema.spec.nullable) {
      return true
    }

    if (valid !== true) {
      return createError({
        path,
        message,
      })
    }

    return true
  })
}

function isValidState(ref: string, message: string) {
  return this.test('is-state-valid', message, function (value: string) {
    const { path, createError } = this

    const country = this.resolve(ref)
    if (!country) return true

    const countryCode = countriesList.getAlpha2Code(country, _.get(store.getState(), 'locale.language.shortIsoCode', 'en'))
    const countryStates = getStatesNames(countryCode)
    if (!countryStates.length || (value && countryStates.includes(value))) return true

    return createError({
      path,
      message,
    })
  })
}

function isRequired(fieldsRequired: FieldsRequiredType, message: string) {
  return this.test('is-required', message, function (value: string) {
    const { path, createError } = this
    if (fieldsRequired && fieldsRequired[path as keyof typeof fieldsRequired]) {
      if (!value) {
        return createError({
          path,
          message,
        })
      }
    }
    return true
  })
}

/**
 *
 * @param ref
 * @param fieldsRequired
 * @param message // one message for all erros e.g A valid tax number is required
 * @returns
 */
function isValidTaxCode(countryRef: string, fieldsRequired: FieldsRequiredType, message: string) {
  const _isRequired = this.isRequired.bind(this)
  return this.test('is-valid-tax-code', message, function (value: string) {
    const { path, createError } = this

    const country = this.resolve(countryRef)
    if (!country) return true
    const countryCode = countriesList.getAlpha2Code(country, _.get(store.getState(), 'locale.language.shortIsoCode', 'en'))

    let valid = false

    if (!_isRequired(fieldsRequired, message) && value.length === 0) {
      valid = true
    } else if (_isRequired(fieldsRequired, message) && !value) {
      valid = false
    } else if (countryCode === COUNTRY_CODE_KR) {
      valid = value && value.length == 13
    } else {
      //before this point, add other countries validation
      return true
    }
    if (valid !== true) {
      return createError({
        path,
        message,
      })
    }

    return true
  })
}

function isValidPhone(ref: string, message: string) {
  return this.test('is-phone-valid', message, function (value: string) {
    const { path, createError } = this

    const country = this.resolve(ref)
    if (!country) return true

    const countryCode = countriesList.getAlpha2Code(country, _.get(store.getState(), 'locale.language.shortIsoCode', 'en'))
    const parsedNumber = parsePhoneNumber(value, countryCode)

    //rare country and not supported
    const regex = /^[-+.() 0-9]{7,15}$/gm
    if (!isSupportedCountry(countryCode) && regex.test(value)) {
      return true
    }
    //rare AU cases #WF-4633:
    //const firstPart = value.slice(0, 6)
    //const spoofedAU = ['+61454', '+61464',]
    // if (countryCode === 'AU' && spoofedAU.includes(firstPart) && regex.test(value)) {
    //   return true
    // }

    if (isValidPhoneNumber(value, countryCode)) {
      return true
    }

    return createError({
      path,
      message,
    })

    return true
  })
}

Yup.addMethod(Yup.string, 'isValidDate', isValidDate)
Yup.addMethod(Yup.date, 'isValidDate', isValidDate)
Yup.addMethod(Yup.string, 'isValidDateRange', isValidDateRange)
Yup.addMethod(Yup.date, 'isValidDateRange', isValidDateRange)
Yup.addMethod(Yup.string, 'equalTo', equalTo)
Yup.addMethod(Yup.string, 'isValidCountryCode', isValidCountryIsoCode)
Yup.addMethod(Yup.string, 'isValidCardNumber', isValidCardNumber)
Yup.addMethod(Yup.string, 'isValidCvv', isValidCvv)
Yup.addMethod(Yup.string, 'isValidCardExpirationDate', isValidCardExpirationDate)
Yup.addMethod(Yup.string, 'isValidPostCode', isValidPostCode)
Yup.addMethod(Yup.string, 'isValidState', isValidState)
Yup.addMethod(Yup.string, 'isRequired', isRequired)
Yup.addMethod(Yup.string, 'isValidTaxCode', isValidTaxCode)
Yup.addMethod(Yup.string, 'isValidPhone', isValidPhone)

export default Yup
