import CloseOutlined from '@mui/icons-material/CloseOutlined'
import { Collapse } from '@mui/material'
import classNames from 'classnames'
import React, { useState } from 'react'
import Flag from 'react-country-flag'
import { useSelector } from 'react-redux'
import styles from './SwitchPanel.module.scss'
import useMatchMedia from '../../../hooks/matchMedia'
import { State } from '../../../services/reducers'
import CurrencySwitcher from '../CurrencySwitcher'
import LanguageSwitcher from '../LanguageSwitcher'
import LocationSwitcher from '../LocationSwitcher'

export enum DISPLAY_MODE {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

type Props = {
  type: DISPLAY_MODE
  onChange?: () => void
}

const renderMobile = () => {
  return (
    <>
      <LocationSwitcher />
      <LanguageSwitcher />
      <CurrencySwitcher />
    </>
  )
}

const SwitchPanel = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const { isDesktop } = useMatchMedia()
  const language = useSelector((state: State) => state.locale.language)
  const channelSites = useSelector((state: State) => state.global.channelSites)
  const channelSite = channelSites.find(cs => cs.current == true) || channelSites[0]
  const { type, onChange } = props

  const onToggle = (event: React.MouseEvent<HTMLSpanElement | SVGSVGElement, MouseEvent>) => {
    setOpen(!open)
  }

  const onHide = () => {
    setOpen(false)
    if (onChange) onChange()
  }

  if (!isDesktop && type === DISPLAY_MODE.MOBILE) {
    return renderMobile()
  }
  if (isDesktop && type === DISPLAY_MODE.MOBILE) {
    return null
  }

  const arrowClasses = classNames(styles['localization__arrow'], {
    [styles['localization__arrow--show']]: open,
  })

  const panelClasses = classNames('desktop--visible', styles['localization__panel'])

  const collapseClasses = {
    root: styles['panel__container'],
    wrapperInner: styles['panel__wrapper'],
  }

  const languageSwitcherClasses = {
    root: classNames({
      [styles['localization__language-switcher']]: isDesktop,
    }),
  }

  const locationSwitcherClasses = {
    root: classNames({
      [styles['localization__location-switcher']]: isDesktop,
    }),
  }

  const currencySwitcherClasses = {
    root: classNames({
      [styles['localization__language-switcher']]: isDesktop,
    }),
  }

  return (
    <>
      <div className={classNames('desktop--visible', 'flex', styles['localization'])}>
        <span className={styles['localization__flag']} onClick={onToggle}>
          <Flag countryCode={channelSite.flag?.toLocaleLowerCase() || 'au'} svg className={styles['flag__icon']} alt="Localization" />
          <span className={styles['flag__currency']}>{channelSite?.currency}</span>
        </span>
        <div className={arrowClasses} />
      </div>
      <div className={panelClasses} onMouseLeave={onHide}>
        <Collapse in={open} classes={collapseClasses} className="content">
          <CloseOutlined onClick={onToggle} className={styles['localization__close']} />
          <LocationSwitcher onChange={onHide} classes={locationSwitcherClasses} />
          <LanguageSwitcher onChange={onHide} classes={languageSwitcherClasses} />
          <CurrencySwitcher onChange={onHide} classes={currencySwitcherClasses} />
        </Collapse>
      </div>
    </>
  )
}

export default SwitchPanel
