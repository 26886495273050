import React from 'react'
import { SubscriberInterface } from '../hooks/events/events.types'

export type ContextProps = {
  redirect?: string | false,
  subscribers: SubscriberInterface[],
}

const AppContext = React.createContext<ContextProps>({
  redirect: false,
  subscribers: [],
})

export default AppContext
