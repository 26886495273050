import { ListItemIcon, MenuItem, SelectChangeEvent } from '@mui/material'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import styles from './SwitcherBase.module.scss'
import { SwitcherBaseItemType } from './SwitcherBase.types'
import SelectField from '../../SelectField'
import TextField from '../../TextField'

type Props = {
  classes?: {
    root?: string
    formControl?: string
    label?: string
    select?: string
  }
  name: string
  label: React.ReactNode
  itemValueKey: string
  itemNameKey: ((item: SwitcherBaseItemType) => string) | string
  onSelected: (event: SelectChangeEvent<any>) => void
  items: SwitcherBaseItemType[]
  value: string | number
  readOnly?: boolean
  renderExtraData?: (item: SwitcherBaseItemType) => JSX.Element
}

const SwitcherBase = (props: Props) => {
  const { classes, name, label, onSelected, items, itemValueKey, itemNameKey, value, readOnly, renderExtraData } = props

  const rootClasses = classes?.root
  const formControlClasses = { root: classNames(styles['form-control'], classes?.root) }
  const labelClasses = { root: classNames(styles['label'], classes?.label) }
  const options = _.toArray(items).map((item, idx) => {
    return (
      <MenuItem
        disabled={_.get(item, itemValueKey) == value}
        key={`${name}-${_.get(item, itemValueKey)}`}
        value={_.get(item, itemValueKey)}
        selected={_.get(item, itemValueKey) == value}
        classes={{ root: styles['item'] }}
      >
        <div>{_.isFunction(itemNameKey) ? itemNameKey(item) : _.get(item, itemNameKey)}</div>
        {renderExtraData ? renderExtraData(item) : _.noop()}
      </MenuItem>
    )
  })

  if (readOnly) {
    return (
      <TextField
        fullWidth
        name={name}
        value={value || ''}
        // classes={{ select: classNames(styles['select'], classes?.select) }}
        formControlProps={{
          classes: formControlClasses,
        }}
        inputProps={{
          readOnly: true,
        }}
        label={label}
        disabled
      />
    )
  }

  return (
    <form autoComplete="off" className={rootClasses}>
      <SelectField
        fullWidth
        name={name}
        value={value || ''}
        onChange={(event: SelectChangeEvent<any>) => {
          onSelected(event)
        }}
        MenuProps={{
          container: () => document.querySelector('#app > .themed'),
        }}
        classes={{ select: classNames(styles['select'], classes?.select) }}
        formControlProps={{
          classes: formControlClasses,
        }}
        label={label}
        readOnly={readOnly}
      >
        {options}
      </SelectField>
    </form>
  )
}

export default SwitcherBase
